<template>
  <div class="visitor-content">

    <!-- True for prefix -->
    <p>Visited {{ date | date( true ) }}</p>

  </div>
</template>

<script>
import Filters from '@/utils/filters'

export default {
  name: 'VisitorContent',

  filters: {
    ...Filters
  },

  props: {
    visitor: Object
  },

  computed: {
    date() {
      return this.visitor.updatedAt
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.visitor-content {
  padding-top: 8px;

  p {
    text-align: center;
  }
}
</style>
