<template>
  <li class="visitor">
    <router-link :to="{ name: 'users', params: { id: visitor.user }}">

      <User :visitor="visitor" :title="name"/>

      <Content :visitor="visitor" :title="visitor.updatedAt"/>

    </router-link>
  </li>
</template>

<script>
import User from '@/components/visitors/User.vue'
import Content from '@/components/visitors/Content.vue'

export default {
  name: 'Visitor',

  components: {
    User,
    Content
  },

  props: {
    visitor: Object
  },

  computed: {
    name() {
      return this.$Utils.User.name( this.visitor )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.visitor {
  background: $gradient-white;
  width: 100%;
  border-radius: 4px;
  padding: 12px;
  position: relative;
  @include box-shadow;
  cursor: pointer;
}
</style>
