<template>
  <div class="visitor-user">

    <span class="profile" :alt="name" :style="style">
      <svg v-if="icon" v-html="icon"/>
    </span>
    
    <p>{{ name }}</p>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VisitorUser',

  props: {
    visitor: Object
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    style() {
      return this.$Utils.User.image( this.visitor.image, this.visitor.color )
    },

    isMe() {
      return this.visitor.user === this.me
    },

    name() {
      return this.isMe ? 'You' : this.$Utils.User.name( this.visitor )
    },
    
    icon() {
      if ( this.visitor.image ) return
      return this.$Utils.User.icon( this.visitor.icon )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.visitor-user {
  @include flex-center;
  flex-direction: column;
  border-bottom: 1px solid $med-light-grey;
  padding-bottom: 8px;

  p {
    display: inline;
    padding-top: 8px;
    font-size: 16px;
  }
}
</style>
