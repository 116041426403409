<template>
  <ul class="visitors-list">

    <Visitor v-for="visitor in visitors" :key="visitor.id" :visitor="visitor"/>

  </ul>
</template>

<script>
import Visitor from '@/components/visitors/Visitor.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'VisitorsList',

  components: {
    Visitor
  },

  computed: {
    ...mapGetters( 'Visitors', {
      visitors: 'visitors'
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.visitors-list {
  overflow-y: scroll;
  padding: $page-padding;
  display: grid;
  grid-gap: $page-padding;
  grid-template-columns: repeat( 3, 1fr );

  @media ( max-width: $tablet ) {
    grid-template-columns: repeat( 2, 1fr );
  }

  @media ( max-width: $mobile ) {
    grid-template-columns: repeat( 1, 1fr );
  }
}
</style>
